$(document).ready(function() {

    if( $('.home').length ) {
        //carrossel dos banenrs
        // var $own = $('.home main .banner .carrossel');
        // $own.owlCarousel({
        // 	responsiveClass:true,
        // 	nav: true,
        // 	navText: [],
        //     items:1
        // });

        sizeFooter();
    }

});

$(window).on("load", function() {
    sizeFooter();
});

$(window).on("resize", function() {
    sizeFooter();
});

function sizeFooter () {
    $("header").css("padding-top", "inherit");
    $("main").css("padding-bottom", "inherit");

    var hWindow = $(window).outerHeight(),
        hFooter = $("footer").outerHeight(),
        hContent = $("header").outerHeight() + $("main").outerHeight() + hFooter,
        hDiff = hWindow - hContent;

        // console.log(hWindow);
        // console.log(hFooter);
        // console.log(hDiff);
        
    if (hDiff > 0) {
        var padding = hDiff/2;
        // console.log(padding);

        $("header").css("padding-top", padding+"px");
        $("main").css("padding-bottom", padding+"px");
    }
    
}